import { Injectable, inject } from '@angular/core';
import { DsSnackbar, DsSnackbarType } from '@design-system/feature/snackbar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { tap } from 'rxjs/operators';
import { RootActions } from './root.actions';

@Injectable()
export class RootEffects {
  private actions$: Actions = inject(Actions);

  showSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.ShowSnackbar),
        tap((payload) =>
          this.snackbar.queue(payload.message, {
            type: payload.snackbar_type,
          }),
        ),
      ),
    { dispatch: false },
  );

  showErrorSnackbar$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(RootActions.ShowErrorSnackbar),
        tap(() =>
          this.snackbar.queue(
            this.translatePipe.instant('telematic_lab.message.error'),
            {
              type: DsSnackbarType.Error,
            },
          ),
        ),
      ),
    { dispatch: false },
  );

  constructor(
    private translatePipe: TranslateService,
    private snackbar: DsSnackbar,
  ) {}
}
