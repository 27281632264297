// Trigger build and delete
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ActivatedRouteSnapshot, RouterModule, Routes } from '@angular/router';
import { BASE_PATH as BPM_BASE_PATH } from '@data-access/bpm-generated';
import {
  AppWrapperModule,
  DS_APP_WRAPPER_CONFIG,
} from '@design-system/feature/app-wrapper-v2';
import {
  AuthGuard,
  AuthPermissions,
  AuthTokens,
  SharedFeatAuthModule,
} from '@features/auth';
import {
  HEALTH_CHECK_OPTIONS_TOKEN,
  HealthCheckBannerOptions,
} from '@features/hc-status-banner';
import { SharedLibFeatTranslationModule } from '@features/translation';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { BASE_PATH as USER_PROFILE_BASE_PATH } from '@paldesk/shared-lib/data-access/identity-service-generated';
import { BASE_PATH } from '@paldesk/shared-lib/data-access/provisioning-service-generated';
import { SharedLibFeaturesFreshdeskWidgetModule } from '@paldesk/shared-lib/features/freshdesk-widget';
import { APPLICATION_INSIGHTS_KEY } from '@shared-lib/app-insights';
import {
  GoogleAnalytics4MeasurementId,
  GoogleAnalytics4Module,
} from '@shared-lib/google-analytics';
import {
  API_MANAGEMENT_CONFIG_TOKEN,
  HttpErrorInterceptor,
  HttpInterceptorModule,
  HttpInterceptorTokens,
} from '@utils/interceptors';
import { environment } from '../environments/environment';
import { AppComponent } from './app.component';
import { ROLES } from './roles';
import { SharedTelematicLabModule } from './shared/shared.module';
import { TelematicLabEffects, TelematicLabReducers } from './shared/store';

export const appRoutes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'communication',
        pathMatch: 'full',
      },
      {
        path: 'communication',
        loadChildren: () =>
          import('./communication/communication.module').then(
            (m) => m.CommunicationModule,
          ),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          title: 'telematic_lab.communication.name',
        },
      },
      {
        path: 'communication-response',
        loadChildren: () =>
          import('./communication-response/communication-response.module').then(
            (m) => m.CommunicationResponseModule,
          ),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          title: 'telematic_lab.communication_response.name',
        },
      },
      {
        path: 'admin',
        loadChildren: () =>
          import('./admin/admin.module').then((m) => m.AdminModule),
        canActivate: [
          (next: ActivatedRouteSnapshot) => inject(AuthGuard).canActivate(next),
        ],
        data: {
          permissions: {
            roles: [ROLES.TELEMATICLAB_ADMIN],
          } as AuthPermissions,
          title: 'telematic_lab.admin.name',
        },
      },
    ],
  },
  {
    path: 'callback',
    children: [],
  },
  {
    path: 'special',
    loadChildren: () =>
      import('@design-system/feature/special-pages').then(
        (m) => m.DsSpecialPagesModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'special',
  },
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    SharedLibFeatTranslationModule,
    BrowserModule,
    BrowserAnimationsModule,
    RouterModule.forRoot(appRoutes),
    HttpClientModule,
    AppWrapperModule.forRoot([
      {
        provide: DS_APP_WRAPPER_CONFIG,
        useFactory: () => ({
          apiBasePath: environment.palfinger_api_management,
          productScreenBasePath: environment.LINKS.PRODUCT_SCREEN,
          isNativeApp: false,
          paldeskBasePath: environment.LINKS.PALDESK_BASEPATH,
          paldeskSupportPath: environment.LINKS.STATUS_PALDESK_PAGE,
          appInsightsRole: 'FE: Telematic Lab',
          appGatewayBasePath: environment.palfinger_app_gateway,
          appName: 'Telematic Lab',
        }),
      },
      {
        provide: APPLICATION_INSIGHTS_KEY,
        useFactory: () => environment.application_insights_key,
      },
    ]),
    HttpInterceptorModule.forRoot({
      provide: API_MANAGEMENT_CONFIG_TOKEN,
      useFactory: () => ({
        subscriptionKey: environment.palfinger_api_management_subs_key,
        whitelistedDomains: environment.domainsForApiManagementSubsKey,
      }),
    }),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          maxAge: 50,
          connectInZone: true,
        }),
    SharedFeatAuthModule.forRoot(),
    StoreModule.forRoot(TelematicLabReducers, {
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot([]),
    TelematicLabEffects,
    SharedTelematicLabModule,
    SharedLibFeaturesFreshdeskWidgetModule.forRoot({
      widgetId: 15000000344,
    }),
    GoogleAnalytics4Module.forRoot({
      provide: GoogleAnalytics4MeasurementId,
      useFactory: () => environment.google_analytics_id,
    }),
  ],
  providers: [
    // HTTP INTERCEPTORS
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
      provide: HttpInterceptorTokens.identityServerAuthConf,
      useFactory: () => environment.identityServerAuthConf,
    },
    // AUTH
    {
      provide: AuthTokens.oidcClientId,
      useFactory: () => environment.oidc_clientid,
    },
    {
      provide: AuthTokens.oidcScope,
      useFactory: () => environment.oidc_scope,
    },
    {
      provide: AuthTokens.securityTokenService,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    {
      provide: AuthTokens.postLogoutRedirectUri,
      useFactory: () => environment.LINKS.POST_LOGOUT_REDIRECT_URI,
    },
    {
      provide: USER_PROFILE_BASE_PATH,
      useFactory: () => environment.LINKS.SECURITY_TOKEN_SERVICE,
    },
    // SERVICES
    {
      provide: BASE_PATH,
      useFactory: () =>
        environment.palfinger_app_gateway_connected + '/conn-prov-api',
      multi: false,
    },
    {
      provide: BPM_BASE_PATH,
      useFactory: () => environment.palfinger_app_gateway + '/bpm',
    },
    // HealthCheck Status Banner
    {
      provide: HEALTH_CHECK_OPTIONS_TOKEN,
      useFactory: (): HealthCheckBannerOptions => ({
        productsToCheck: ['PALFINGER Connected Telematic'], // Paldesk Core is always checked as well
        statusPaldeskApiUri:
          environment.palfinger_app_gateway + '/status/api/v1/health',
        statusPaldeskPageUri: environment.LINKS.STATUS_PALDESK_PAGE,
      }),
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
